@import "variables.scss";

.vacancy-status-badge {
	font-size: 14px;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	color: $text-color;
}

.vacancy-status-badge::before {
	display: inline-block;
	content: " ";
	width: 10px;
	height: 10px;
	background-color: #d8d8d8;
	border-radius: 50%;
	margin-right: 8px;
}

.vacancy-status-badge-true::before {
	background-color: $green;
}
