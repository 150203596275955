@import "variables.scss";

.card-list-item {
	width: 100%;
	height: 100%;
	flex-direction: column;
	flex-grow: 1;
	text-align: left;
	border-radius: $border-radius;
	color: $text-color;
	word-wrap: break-all;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-size: 14px;

	&,
	&:hover {
		text-decoration: none;
	}

	@media print {
		flex-direction: row;
	}
}

@media (min-width: 1130px) {
	.card-list-item-container {
		display: flex;
	}
	.card-narrow > .card-list-item-container {
		display: block;
	}
}

.card-list-item-borders {
	border: 1px solid $border-color;
	border-radius: $border-radius;
	height: 100%;

	@media print {
		border: 0;
		border-top: 1px solid $border-color;
		&.card-list-item-borders-last {
			border-bottom: 1px solid $border-color;
		}
		page-break-inside: avoid;
	}
}

@media (min-width: 1130px) {
	.card-list-item-borders {
		display: block;
		padding: 20px 0px;
		border: 0;
		border-radius: 0;
		border-top: 1px solid $border-color;
		&.card-list-item-borders-last {
			border-bottom: 1px solid $border-color;
		}
	}
}

@mixin card-wide {
	flex-direction: row;

	max-width: none;

	.card-list-item__image-container {
		max-width: 300px;
		margin-bottom: 0;
	}

	.card-list-item__content {
		padding: 0;
	}

	.card-list-item__link {
		display: none;
	}
}

@media (min-width: 1130px) {
	.card-list-item:not(.card-narrow) {
		@include card-wide;
	}

	.card-desktop {
		display: block;
	}

	.card-mobile {
		display: none;
	}
}

.card-list-item__image-container {
	width: 100%;
	border-top-left-radius: $border-radius;
	border-top-right-radius: $border-radius;
	margin-right: 20px;
	height: 200px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: $border-color url("/icons/icon-house.svg") 50% 50% no-repeat;
	object-fit: cover;

	&.has-pic {
		background-size: cover;
		background-position: center;
	}

	@media (min-width: 1130px) {
		border-bottom-left-radius: $border-radius;
		border-bottom-right-radius: $border-radius;
	}

	@media print {
		max-width: 250px;
		min-width: 250px;
		max-height: 167px;
		margin: 20px 0;
	}
}

// .card-list-item__image {
// 	display: block;
// 	width: 100%;
// }

.card-list-item__content {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding: 20px;
	flex-grow: 1;

	@media print {
		max-width: 400px;
		min-width: 400px;
	}
}

.card-list-item__buttons {
	display: flex;
	flex-direction: column;
	padding: 0 1rem;

	& > * {
		margin-bottom: 10px;
	}

	@media (min-width: 800px) {
		display: inline-block;
		max-width: 240px;
		text-align: right;
		padding: 0;
	}
}

.card-nursinghome-vacancy-status-badge {
	line-height: 26px;
	margin: 2px 0 1px 0;
}

.card-list-item__subheader {
	text-transform: uppercase;
	line-height: 26px;
	font-size: 14px;
}

.card-list-item__header {
	font-size: 19px;
	font-weight: 700;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	color: $text-color;
	line-height: 26px;
	margin: 3px 0 3px 0;
}

.card-list-item__text {
	line-height: 26px;
	font-size: 14px;
}

.card-list-item__text--lah {
	margin-top: 4px;
}

.card-list-item__tag {
	height: 25px;
	background-color: $border-color;
	border-radius: $border-radius;
	display: inline-block;
	line-height: 25px;
	padding: 0 5px;
	margin: 5px 0;
	color: $text-color;
}

.card-list-item__spacer {
	flex-grow: 1;
	@media (min-width: 1130px) {
		display: none;
	}
}

.card-list-item__link {
	padding: 20px;
	padding-top: 0;
}

.card-list-item__link-button {
	margin-top: 0.5em;
	cursor: pointer;
	font-weight: 400;
	border: none;
	background: none;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-size: 14px;
	padding: 0;
	color: $primary-blue;
}

.card-list-item__visiting-info {
	display: none;

	@media print {
		display: block;
		padding: 20px;
		border-left: 1px solid $border-color;
		max-width: 350px;
		min-width: 350px;
	}
}

.card-nursing-home-status {
	margin-top: 10px;
	font-size: 16px;

	span {
		font-weight: 500;
	}
}

@media (min-width: 1130px) {
	.card-nursing-home-boxes {
		display: flex;
	}
}

.card-nursing-home-public-status {
	padding: 5px 25px 5px 25px;
	border-left: solid 1px $border-color;
	margin-bottom: 15px;
	min-height: 105px;

	@media (min-width: 1130px) {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 33%;
		text-align: center;
		margin-top: 25px;
		margin-bottom: 0px;
	}

	& > div {
		height: 100%;
	}

	p {
		font-size: 14px;
		margin: 0;
	}

	.card-nursing-home-public-status-header {
		font-size: 16px;
		font-weight: 500;
	}
}

.no-left-border {
	border-left: none;
}

.card-list-item-report-btn {
	height: 30px;
}

.card-list-item-alert-tag {
	position: relative;
	left: -320px;
	width: 240px;
	height: 40px;
	margin-right: -240px;
	background-color: rgba(255, 255, 255, 1);
	opacity: 0.8;

	div {
		display: inline-block;
	}
}

.card-list-item-alert-tag-mark {
	width: 20px;
	height: 40px;
	background-color: rgb(230, 30, 75);
}

.card-list-item-alert-tag-label {
	position: relative;
	top: -16px;
	left: 15px;
	font-weight: 500;
}

.card-nursing-home-alert {
	color: rgb(230, 30, 75);
}

.card-nursing-home-alert-sign {
	width: 25px;
	height: 25px;
	margin: auto;
	margin-bottom: 5px;
	background-image: url("/icons/icon-exclamation-circle.svg");
	background-size: contain;
	background-repeat: no-repeat;
}
