@import "./variables.scss";

.nursinghome-page-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 5rem;
	background-color: $sky-blue;
}

.nursinghome-info-list dt {
	line-height: 24px;
	font-weight: 700;
}
.nursinghome-info-list dd {
	line-height: 24px;
	margin-bottom: 1em;
	white-space: pre-line;
}

.nursinghome-info-list--contact,
.nursinghome-info-list--directions {
	margin-top: 10px;
}

.nursinghome-info-list--contact dd,
.nursinghome-info-list--directions dd {
	line-height: 24px;
	margin-bottom: 0;
}
.nursinghome-info-list--directions dd {
	margin-bottom: 1em;
}

.nursinghome-info-list--directions dd:last-child {
	margin-bottom: 0;
}

.nursinghome-img-inner {
	height: 100%;
	background-position: center;
	background-size: cover;
}

.nursinghome-hero-container {
	position: relative;
	width: 100%;
	height: auto;
	margin-bottom: 40px;
}

.nursinghome-hero-lightbox-button {
	flex: 0 0 100%;
	position: absolute;
	right: 20px;
	bottom: 20px;
	padding: 10px 20px;
	background-color: white;
	border-radius: 2px;
	border: none;
	font-size: 16px;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-weight: 700;
	color: $text-color;
	cursor: pointer;

	&:hover {
		color: $text-color;
	}
}

.nursinghome-hero {
	width: 100%;
	display: flex;
	align-items: stretch;
	flex-flow: column wrap;

	&:not(.nursinghome-hero-n0) {
		height: 400px;
		@media (min-width: 1024px) {
			height: 640px;
			max-height: calc(100vh - 280px);
			min-height: 350px;
		}
	}
}

.nursinghome-hero-img {
	overflow: hidden;
	flex-basis: 50%;
	display: none;
	object-fit: cover;

	&:first-child {
		flex-basis: 100%;
		display: block;
	}

	.nursinghome-img-inner:hover {
		cursor: pointer;
		transform: scale(1.03);
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		cursor: pointer;
	}
}

$hero-border: 5px solid white;

@media (min-width: 1024px) {
	.nursinghome-hero-img {
		display: block;
	}

	.nursinghome-hero-n3 .nursinghome-hero-img {
		width: 50%;
		&:first-child {
			border-right: $hero-border;
		}
		&:nth-child(2) {
			border-bottom: $hero-border;
		}
	}

	.nursinghome-hero-n5 .nursinghome-hero-img {
		width: 25%;
		&:first-child {
			width: 50%;
		}
		&:first-child,
		&:nth-child(2),
		&:nth-child(3) {
			border-right: $hero-border;
		}
		&:nth-child(2),
		&:nth-child(4) {
			border-bottom: $hero-border;
		}
	}
}

.nursinghome-hero-placeholder {
	background-color: $primary-blue;
	height: 480px;
	display: none;
}

.nursinghome-back-link {
	display: block;
	margin-bottom: 32px;
	color: $black;

	&::before {
		content: "←";
		margin-right: 8px;
	}
}

.nursinghome-forward-link {
	display: block;
	margin-bottom: 32px;
	color: $black;

	&::after {
		content: "→";
		margin-right: 8px;
	}
}

.nursinghomeDistrict-container {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 15px;
	align-items: baseline;
}

.nursinghome-info p.nursinghomeDistrict {
	margin-bottom: 0;
	margin-right: 10px;
}

.nursinghome-anchor-details {
	@media (min-width: 800px) {
		display: none;
	}
}

.nursinghome-info-container {
	width: 100%;
	max-width: 1100px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0 20px;

	@media (min-width: 800px) {
		flex-direction: row;
		padding: 0 50px;
	}
}

.nursinghome-info {
	flex-grow: 1;
}

.nursinghome-title {
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-size: 48px;
	line-height: 1.1em;
	font-weight: 800;
	color: $text-color;
	margin-bottom: 10px;
	@media (max-width: 640px) {
		font-size: 36px;
		line-height: 1.1em;
	}
}

.nursinghome-title-vacancy-status-badge {
	vertical-align: middle;
	display: block;
	white-space: nowrap;
	margin-bottom: 32px;
}

.nursinghome-info h3 {
	font-size: 36px;
	color: $text-color;
	margin-bottom: 32px;
	font-weight: 800;
	margin-top: 64px;
	line-height: 40px;
	@media (max-width: 640px) {
		font-size: 32px;
		line-height: 1.1em;
		margin-bottom: 25px;
	}
}

.nursinghome-info p {
	margin-bottom: 32px;
	white-space: pre-line;
}

.nursinghome-info-paragraph-title {
	font-weight: 700;
	margin-bottom: 8px !important;
}

.nursinghome-details-box {
	flex-basis: 360px;
	flex-shrink: 0;
	margin-top: 32px;

	@media (min-width: 800px) {
		margin-left: 64px;
		margin-top: 0;
		top: 20px;
	}
}

.nursinghome-details-box-section {
	padding: 24px;
	margin-bottom: 20px;
	border: 1px solid $light-gray;
	border-radius: $border-radius-lg;
	background-color: $white;
	box-shadow: 0px 0px 5px lighten($black, 75%);
}

.nursinghome-details-box h3 {
	color: inherit;
	font-size: 16px;
	font-weight: 800;
	margin-bottom: 8px;

	&:not(:first-child) {
		margin-top: 16px;
	}
}

.nursinghome-details-box p {
	margin-bottom: 8px;

	&:last-child {
		margin-bottom: 0;
	}

	&.report-info-description {
		margin-bottom: 2rem;
	}
}

.nursinghome-details-logo {
	display: block;
	width: 100px;
	margin: 0 auto 20px auto;
}

.nursinghome-details-heading {
	font-size: 19px;
	font-weight: 700;
	line-height: 24px;
}

.report-info-container {
	margin-top: 15px;
	text-align: center;

	div {
		margin-bottom: 25px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.report-info-item {
		margin: 35px 0 35px 0;
	}

	.report-hidden {
		display: none;
	}

	.read-more-link {
		display: inline-block;
		margin: 1.5rem 0 0.5rem 0;
	}
}

.report-info-header {
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

.report-info-minor-header {
	font-size: 16px;
	font-weight: 500;
}

.report-info-btn {
	width: 100%;
	margin: 20px 0 0 0;
}

.nursinghome-details-box-survey-link {
	margin-bottom: 20px;
	display: block;
}
