@import "variables.scss";
@import "placeholders.scss";

$header-height: 80px;

#app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

#content {
	flex-grow: 1;
	position: relative;
}

.backToTopLink {
	display: block;
	text-align: center;
	margin: 20px 0 50px 0;
	color: $black;
}

.jump-to-content {
	position: absolute;
	top: 5px;
	padding: 10px;
	background-color: #fff;
	left: -99999px;
	min-height: 0;
	height: 1px;
	margin-top: -1px;
	line-height: 35px;
	-webkit-transition: top 1s ease-out, background 1s linear;
	transition: top 1s ease-out, background 1s linear;
	z-index: 100;
	border-radius: $border-radius;
}
.jump-to-content:focus {
	top: 5px;
	left: 5px;
	background: $primary-blue;
	color: $text-color;
	-webkit-transition: top 0.1s ease-in, background 0.5s linear;
	transition: top 0.1s ease-in, background 0.5s linear;
	height: auto;
	text-decoration: underline;
}

.header {
	@extend %border-bottom-orange;

	display: flex;
	height: $header-height;
	padding: 0 20px;
	align-items: center;
	justify-content: space-between;
	box-sizing: content-box;

	@media print {
		justify-content: center;
	}

	&--green {
		@extend %border-bottom-green;
	}

	&--blue {
		@extend %border-bottom-blue;
	}
}

.header-shadow {
	-webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}

.footer {
	padding: 20px;
	box-sizing: border-box;
	flex-direction: column;
	height: auto;
	min-height: $header-height;
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media (min-width: 1024px) {
		flex-direction: row;
	}
}

.footer .logo {
	margin-bottom: 20px;

	@media (min-width: 1024px) {
		margin-bottom: 0;
	}
}

.footer-links {
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@media (min-width: 1024px) {
		flex-direction: row;
	}
}

.footer-link {
	text-align: center;
	margin-bottom: 8px;

	@media (min-width: 1024px) {
		text-align: left;
		margin-bottom: 0;
		&:not(:last-child)::after {
			content: "|";
			margin: 0 20px;
		}
	}
}

.logo-container a {
	display: flex;
	align-items: center;
	text-decoration: none;
	white-space: nowrap;
}

.logo-container {
	margin-right: 20px;
}

.logo-container a:hover {
	text-decoration: none;
}

.logo-header {
	width: 18rem;
	margin: 0 0.4rem 0.375rem 0.15rem;
}
@media screen and (max-width: 500px) {
	.logo-header {
		width: 12rem;
		margin: 0 0.4rem 0.375rem 0.15rem;
	}
}

.logo-footer {
	width: 20rem;
	margin: 0 1.25rem 0.375rem 0.625rem;
}

.minor-header {
	margin-top: 5px;
	margin-left: 15px;
	width: 250px;
	font-size: 16px;
	font-weight: 700;
}

.title {
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	text-decoration: none;
	margin-top: 5px;
	font-size: 1rem;
	white-space: normal;
	line-height: 1.2;
	font-weight: 400;
}

#page-nav {
	display: flex;
	height: 80px;
	align-items: center;
	width: 100%;
	justify-content: space-between;

	@media print {
		display: none;
	}
}

#page-nav label,
#hamburger {
	display: none;
}

.nav-menus {
	display: flex;
	height: 80px;
	align-items: center;
	width: 100%;
	justify-content: center;

	.btn-logout {
		margin-left: 1rem;
	}
}

.nav-hidden {
	visibility: hidden;
}

.nav-menu {
	display: flex;
	height: $header-height;
}

.nav-menu a {
	color: $text-color;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	vertical-align: middle;
	margin: 0;
	line-height: 86px;
	height: $header-height;
	padding: 0 1.4rem;
	display: inline-block;
	text-decoration: none;
	border-bottom: 5px solid transparent;
}

.nav-menu a:hover {
	border-bottom: 3px solid $black;
	text-decoration: none;
}

.nav-menu li a.selected {
	font-weight: 700;
}

.nav-menu li a.selected {
	background-color: $peach;

	&:hover {
		border-bottom: none;
	}
}

.nav-menu--language li.selected {
	font-weight: 700;
	padding: 0 10px;
}

.nav-menu--language li.separator {
	padding: 0 10px;
}

.nav-menu--language {
	display: flex;
}

.nav-menu--language li {
	text-transform: none;
	margin: 0;
	color: $text-color;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	vertical-align: middle;
	line-height: 86px;
	height: $header-height;
	max-height: 80px;
}

.nav-menu--language a {
	color: $text-color;
	text-decoration: none;
	display: inline-block;
	padding: 0 10px;
	height: $header-height;
	line-height: 86px;
	white-space: nowrap;
}

.nav-menu--language a:hover {
	border-bottom: 3px solid $primary-blue;
	text-decoration: none;
}

.lang-link.active {
	font-weight: 700;
}

.lang-link-short {
	display: none;
}

/* [ON SMALL SCREENS] */
@media screen and (max-width: 1000px) {
	/* Show Hamburger */

	#page-nav,
	.nav-menus {
		justify-content: flex-end;
	}

	#hamburger {
		display: inline;
		position: absolute !important;
		clip: rect(1px, 1px, 1px, 1px);
		padding: 0 !important;
		border: 0 !important;
		height: 1px !important;
		width: 1px !important;
		overflow: hidden;
	}

	#page-nav label.menu-btn {
		display: inline-block;
		color: $text-color;
		background: #fff;
		font-style: normal;
		font-size: 15px;
		padding: 10px;
		white-space: nowrap;
		user-select: none;
		text-transform: uppercase;
		border: 1px $border-color solid;
		border-radius: $border-radius;
		cursor: pointer;
	}

	/* Break down menu items into vertical */
	#page-nav ul li {
		display: block;
	}

	#page-nav ul.nav-menu li {
		border-top: 1px solid $border-color;
		display: block;
		background-color: $border-color;
		height: auto;
		line-height: 60px;
		text-align: center;
	}
	#page-nav ul.nav-menu li a {
		height: 60px;
		line-height: 60px;
		width: 100%;
		margin: 0;
		border: 0;
		display: block;
	}

	#page-nav ul.nav-menu li a:hover {
		color: $text-color;
		text-decoration: underline;
	}
	#page-nav ul.nav-menu li a.selected {
		background-color: $peach;
		color: $text-color;
	}

	#page-nav ul.nav-menu {
		display: block;
		margin: 0;
		height: auto;
	}

	#page-nav ul.nav-menu {
		border-bottom: 1px solid $border-color;
	}

	#page-nav ul.nav-menu--language {
		margin-left: 20px;
		display: flex;
	}

	#page-nav ul.nav-menu--language a:hover {
		border: 0;
	}

	/* Toggle show/hide menu on checkbox click */
	#page-nav .nav-menus {
		z-index: 10;
		display: none;
		position: absolute;
		top: 80px;
		right: 0;
		width: 350px;
		background-color: #fff;
		height: auto;
		border-radius: $border-radius;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
	}
	#page-nav input:checked ~ .nav-menus {
		display: block;
	}

	#page-nav input:checked ~ .menu-btn {
		background-color: #fff;
		color: $text-color;
		border: 1px solid $text-color;
	}

	[type="checkbox"]:focus ~ .menu-btn {
		outline: 1px dotted #212121;
		outline: 5px auto -webkit-focus-ring-color;
	}

	.lang-link-long {
		display: none;
	}

	.lang-link-short {
		display: block;
	}
}

@media screen and (max-width: 640px) {
	#page-nav {
		justify-content: space-between;
	}

	#page-nav ul.nav-menu--language {
		margin-left: auto;
	}

	.menu-btn {
		margin-left: auto;
	}

	.header {
		padding: 0 0px 0 10px;
	}

	.title {
		display: none;
	}
	#page-nav .nav-menus {
		width: 100%;
	}
	.logo-container {
		margin: 0;
	}
	.logo {
		margin-right: 10px;
		margin-left: 0;
	}
	.nav-menu--language li.separator {
		padding: 0 5px;
	}
}
