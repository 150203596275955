@import "variables.scss";

.save-and-empty-container {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 20px;

	@media screen and (min-width: 1024px) {
		position: static;
		margin-top: 20px;
		margin-right: 5px;
		padding: 0;
	}
}

.menu-empty-button {
	font-weight: 400;
	border: none;
	background: none;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-size: 16px;
	padding: 0;

	&:not(:disabled) {
		cursor: pointer;
	}
}

.menu-empty-button:not(:disabled):hover {
	color: $primary-blue;
}

.header-item {
	font-weight: 500;
	margin-bottom: 25px;
	line-height: 1.5;
}

.text-item {
	margin: 25px 0;
	padding-right: 5px;
}

.option-text-has-subtext {
	font-weight: 500;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	line-height: 22px;
}

.option-subtext {
	line-height: 22px;
}

.with-margin {
	margin-left: 10px;
}

.with-bold {
	font-weight: 700;
}

.option-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	width: 41rem;
	max-height: 20rem;

	@media screen and (max-width: 1024px) {
		max-height: calc(100vh - 200px);
		width: auto;
	}
}
