@import "variables.scss";

.radio-container {
	flex: 1;
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	.radio-label {
		width: 100%;
	}
}

.radio-button {
	position: absolute;
	width: 1px;
	height: 1px;
	opacity: 0;
}

.radio-button:focus + .radio-label .radio-box {
	box-shadow: 0px 0px 2px 3px $primary-blue;
}

.radio-box {
	width: 30px;
	height: 30px;
	background-color: transparent;
	border-radius: 2px;
	border: 1px solid $input-border;
	display: flex;
	justify-items: center;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	border-radius: 50%;
	background-size: 24px;
	color: $text-color;
	font-weight: 500;
	padding: 6px 0 0 1px;
}

.radio-box-selected {
	background: $link-color url("/icons/icon-checkmark.svg") 50% 50% no-repeat;
	background-size: 20px;
	border-color: $link-color;
	color: #fff;
}

.radio-checkmark {
	vertical-align: middle;
}

.radio-label {
	display: flex;
	align-items: center;
	padding: 4px 5px;
	margin-left: -5px;
	cursor: pointer;
}

.radio-label:hover {
	background-color: #f5f5f5;
}

.radio-label-taged {
	background-color: rgba($color: #fff, $alpha: 0) !important;
}

.radio-label:hover .radio-box {
	background: transparent url("/icons/icon-checkmark-hover.svg") 50% 50%
		no-repeat;
	background-size: 20px;
}

.radio-label:hover .radio-box-selected {
	background: $link-color url("/icons/icon-checkmark.svg") 50% 50% no-repeat;
	background-size: 20px;
	border-color: $link-color;
}

.radio-label-children {
	margin-left: 16px;
	align-self: center;
	max-width: 600px;
}

.radio-box-taged {
	background-image: none !important;
}
