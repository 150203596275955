@import "variables.scss";

.page-survey-results {
	max-width: 800px;
	margin: 1rem auto;
	padding: 0 30px 0 30px;
}

.page-survey-results-set {
	margin-bottom: 100px;
}

.page-survey-results-item {
	margin: 0 0 30px 0;
}

.page-survey-results-title {
	margin-top: 50px;
	font-weight: 800;
	color: $text-color;
}

.page-survey-results-result {
	height: 60px;
	font-size: 18px;
}

.page-survey-results-minor-title {
	font-size: 18px;
	margin: 10px 0 35px 0;
}

.page-survey-results-bold {
	font-weight: 700;
}

.page-survey-results-result-question {
	display: inline-block;
	width: 240px;
}

.page-survey-results-result-score {
	display: inline-block;
}

.page-survey-results-result-value {
	display: inline-block;
	width: 75px;
	text-align: center;
}

.page-survey-results-result-image {
	display: inline-block;
	position: relative;
	top: 8px;
	width: 35px;
	height: 35px;
	margin-left: 5px;
	background-position: bottom;
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 10;
}

.progress-background {
	width: 300px;
	position: relative;
	top: -22px;
	height: 28px;
	border: solid 1px $input-border;
}

.star-full {
	background-image: url("/icons/star-full-alt.svg");
}

.star-half {
	background-image: url("/icons/star-half-alt.svg");
}

.star-none {
	background-image: url("/icons/star-none.svg");
}

.page-survey-results-footer {
	margin-top: 100px;

	ul {
		list-style: disc;
		line-height: 26px;
		padding-inline-start: 2rem;
		margin-bottom: 1.5em;

		li {
			margin-bottom: 0.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.page-survey-results-answer-container {
	max-width: 100%;
	margin-top: 60px;

	.page-survey-results-answer-header {
		color: $text-color;
		background-color: $primary-blue;
		font-size: 18px;
		padding: 15px;
	}

	.page-survey-results-answer-content {
		max-width: 100%;
		padding: 15px;
		border: solid 1px $input-border;
		height: 300px;
		overflow-y: scroll;

		.answer {
			max-width: 100%;
			margin: 0 0 30px 0;
			padding-bottom: 30px;
			width: 100%;
			border-bottom: solid 1px $input-border;

			.answer-date {
				margin-bottom: 0;
			}

			.response-header {
				padding-left: 40px;
				margin-bottom: 0;
			}

			.response {
				padding-left: 40px;
			}
		}
	}
}
