.auth-container {
	padding: 3rem 2rem 0 2rem;
	max-width: 42.5rem;
	margin: 0 auto;

	h2 {
		margin-bottom: 2.5rem;
	}

	.login-form {
		.button-container {
			padding-top: 1rem;
			float: right;
		}
	}
}
