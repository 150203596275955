@import "variables.scss";

.page-survey-section {
	width: 100%;
	border-bottom: 1px solid $border-color;
	padding: 40px 0 100px 0;
}

.survey-card-container {
	width: 100%;
	margin: auto;
	text-align: center;
}

.survey-card-inner {
	display: inline-block;

	div {
		vertical-align: bottom;
	}

	.survey-icon {
		margin-right: 80px;
		padding-top: 20px;

		img {
			width: 150px;
			height: 150px;
		}
	}

	textarea {
		width: 100%;
		height: 300px;
		font-size: 18px;
		font-family: filson-pro, Verdana, "Open sans", sans-serif;
		padding: 5px;
	}
}

@media (max-width: 1149px) {
	.survey-icon {
		display: none;
	}
}

.survey-card-question {
	width: 800px;
}

.survey-card--header-container {
	border-bottom: 1px solid $border-color;
	text-align: center;
	padding: 0 0 20px 0;

	p {
		text-align: left;

		&:first-of-type {
			margin-top: 1rem;
		}
	}
}

.survey-card--header {
	font-size: 20px;
	font-weight: 800;
	margin-bottom: 0;
}

.survey-card--desc {
	font-size: 16px;
	margin-bottom: 0;
}

.survey-card-line {
	border-bottom: solid 1px $border-color;
	width: 105px;
	position: relative;
}

.line-1 {
	left: 105px;
	top: 43px;
}

.line-2 {
	left: 265px;
	top: 42px;
}

.line-3 {
	left: 425px;
	top: 41px;
}

.line-4 {
	left: 585px;
	top: 40px;
}

.survey-card--inputs {
	.radio-container {
		display: inline-flex;
		padding: 25px 0 0 0;

		.radio-label {
			display: block;
			width: 165px;
			padding: 0;
			text-align: center;

			span {
				margin: auto;
				display: block;
			}

			.radio-label-children {
				margin-top: 15px;
			}
		}
	}
}

.survey-send-btn-container {
	margin: auto;
	width: 700px;
	padding: 30px 5px 50px 0;
	margin-bottom: 100px;

	.btn {
		width: 180px;
		height: 60px;
	}

	.btn-right {
		float: right;
	}
}

.page-survey-done {
	margin-top: 25vh;
	margin-left: 15vw;
}

.header-inline {
	display: inline-block;
	margin-right: 10px;
}

.page-survey-minor-header {
	margin: 30px 0 20px 150px;
	font-size: 16px;

	span {
		font-weight: 500;
	}
}

.survey-login-error {
	margin-top: 5px;
	color: $error-color;
}

.login-input-label {
	display: block;
	margin-bottom: 10px;
}

.login-container {
	margin-top: 20vh;
	margin-bottom: 150px;
	margin-left: 15vw;

	h2 {
		margin-bottom: 60px;
	}

	div {
		margin-top: 40px;
		margin-bottom: -10px;
		width: 350px;
	}

	input {
		border: none;
		border-bottom: solid 1px $border-color;
		font-size: 25px;
		line-height: 35px;
	}

	.align-right {
		text-align: right;
	}

	span {
		margin-right: 40px;
	}
}
