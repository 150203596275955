@import "variables.scss";

.page-accessibility {
	padding: 20px;
}

.page-accessibility-content {
	max-width: 680px;
	margin: 0 auto 70px auto;
}

.page-accessibility-title {
	margin-bottom: 1em;

	margin-top: 1em;
}
