@import "variables.scss";

.checkbox-container {
	flex: 1;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	min-width: 180px;

	&.disabled {
		.checkbox-label {
			color: $text-color;

			.checkbox-box-selected {
				background: $input-border url("/icons/icon-checkmark.svg") 50%
					50% no-repeat;
				border-color: $input-border;
			}

			&:hover {
				background-color: transparent;

				.checkbox-box {
					background: none;
				}

				.checkbox-box-selected {
					background: $input-border url("/icons/icon-checkmark.svg")
						50% 50% no-repeat;
				}
			}
		}
	}
}

.checkbox-button {
	position: absolute;
	width: 1px;
	height: 1px;
	opacity: 0;
}

.checkbox-button:focus + .checkbox-label .checkbox-box {
	box-shadow: 0px 0px 1px 1px $link-color;
	border-color: $link-color;
}

.checkbox-box {
	width: 20px;
	height: 20px;
	flex-basis: 20px;
	flex-shrink: 0;
	background-color: transparent;
	border-radius: $border-radius;
	border: 1px solid $input-border;
	display: flex;
	justify-items: center;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.checkbox-box-selected {
	background: $link-color url("/icons/icon-checkmark.svg") 50% 50% no-repeat;
	border-color: $link-color;
}

.checkbox-checkmark {
	vertical-align: middle;
}

.checkbox-label {
	display: flex;
	width: 100%;
	padding: 4px 5px;
	cursor: pointer;
	margin-left: -5px;
	color: $text-color;
}

.checkbox-label:hover {
	background-color: #f5f5f5;
}

.checkbox-label:hover .checkbox-box {
	background: transparent url("/icons/icon-checkmark-hover.svg") 50% 50%
		no-repeat;
}

.checkbox-label:hover .checkbox-box-selected {
	background: $link-color url("/icons/icon-checkmark.svg") 50% 50% no-repeat;
}

.checkbox-label-children {
	margin-left: 16px;
	align-self: center;
}
