@import "variables.scss";

.page-error {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;

	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.page-error-logo {
	width: 150px;
	height: 150px;
	margin-bottom: 70px;
}

.page-error-title {
	margin-bottom: 20px;
	text-align: center;
}
