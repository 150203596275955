@import "variables.scss";

$column-width: 1024px;

.jumbotron {
	width: 100%;
	max-height: calc(100vh - 80px - 200px);
	min-height: 400px;
	height: 100vh;
	background: $primary-blue url("/hero-image-alt.jpg") 50% 50% no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.jumbotron__header {
	color: #fff;
	font-weight: 800;
	font-size: 48px;
	max-width: 680px;
	width: 680px;
	margin: 0 auto 50px auto;
	display: block;
	line-height: 52px;
	text-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
	@media screen and (max-height: 800px) {
		margin-bottom: 40px;
	}
}

.location-picker {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: $column-width;
	width: $column-width;
	background-color: #fff;
	border-radius: $border-radius;
	box-shadow: 0 16px 40px rgba(0, 0, 0, 0.12);
	padding: 20px 30px;
	margin: 0 auto 80px auto;
	@media screen and (max-height: 800px) {
		margin-bottom: 45px;
	}
}

.location-picker-label {
	font-weight: 700;
}

.location-picker-select {
	flex-grow: 1;
	height: 100%;
	margin: 0 40px 0 10px;
}

.location-picker-select .button-dropdown-container {
	height: 100%;

	.button-dropdown {
		height: 45px;
		padding: 4px 5px 0 10px;
		width: 100%;
		font-weight: 400;
		text-transform: none;
		font-family: filson-pro, Verdana, "Open sans", sans-serif;
		font-size: 16px;
		color: $text-color;
	}
}

.location-picker-select select {
	height: 100%;
	width: 100%;
	background: none;
	border: none;
	border-radius: 0;
	font-size: 16px;
	color: $text-color;
}

.landing-cta {
	width: 200px;
	height: 45px;
}

.content-column {
	// max-width: $column-width;
	// padding: 20px;
	margin: 0 auto;
	padding: 0;
	// margin-top: 60px;
}

.content-block {
	max-width: 680px;
	margin: 2.25rem auto;

	ol,
	ul {
		line-height: 26px;
		padding-inline-start: 2rem;
		margin-bottom: 1.5em;

		li {
			margin-bottom: 0.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	ol {
		list-style: decimal;
	}

	ul {
		list-style: disc;
	}

	&-container {
		padding: 1.25rem;

		&--vanilla {
			background-color: $vanilla;
		}

		&--blue {
			background-color: $sky-blue;
		}
	}
}
.content-block--wide {
	max-width: 1024px;
	margin: 0 auto 70px auto;
	text-align: center;
}

p.content-block-paragraph {
	text-align: center;
}
.process-diagram {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.process-diagram__item {
	margin: 0 10px;
	text-align: center;
	max-width: 250px;
}

.process-diagram__item__img {
	display: inline-block;
	height: 150px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.faqHeadline {
	margin-top: 50px;
}

.faq-list dt {
	font-weight: 700;
	margin-top: 20px;
	line-height: 26px;
}

.faq-list dd {
	line-height: 26px;
}

@media screen and (max-width: 1024px) {
	.jumbotron {
		max-height: 520px;
		background-position-y: -100px;
	}

	.location-picker {
		flex-direction: column;
		margin-bottom: 0;
		width: 100%;
		border-radius: 0;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

		.location-picker-select {
			height: auto;
			align-self: stretch;
			margin: 0;
		}

		.button-dropdown {
			text-align: center;
		}

		.landing-cta {
			flex-basis: 45px;
		}

		& > *:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	.jumbotron__header {
		max-width: 100%;
		padding: 20px;
	}
}

@media screen and (max-width: 850px) {
	.process-diagram__item {
		width: 100%;
		max-width: 100%;
		margin: 0;
	}
}

.anchor {
	&-list {
		@media screen and (min-width: 640px) {
			display: flex;
			justify-content: space-between;
		}
	}

	&-link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-bottom: 1rem;
		white-space: pre-wrap;

		&:hover {
			text-decoration: none;
		}

		@media screen and (min-width: 640px) {
			width: 32%;
			margin-right: 1%;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
