/* Colors */
$border-color: #e8e8e8;
$input-border: #6e6e6e;
// $primary-blue: #0050bb;
$error-color: #ce0000;
//$text-color: #0f0f0f;
// $link-color: #3373c9;
// $green: #c6db00;

/*LU Colors*/

$primary-blue: #3e97d3;
$secondary-blue: #63b4ec;
$black: #000;
$white: #fff;
$orange: #ff5f14;
$green: #5fae60;
$yellow: #fcf77d;

$peach: #ffb69c;
$vanilla: #fffcdc;
$sky-blue: #daeefa;

$gray: #949494;
$dark-gray: #8a8a8a;
$light-gray: #dbdbdb;
$ligher-gray: #f2f2f2;

$link-color: #257bb3;
$text-color: #000000;

// $headline-color: #6e6e6e;

$border-radius: 5px;
$border-radius-lg: 10px;
