@import "variables.scss";

.button-dropdown-background {
	width: 110%;
	height: 110%;
	position: fixed;
	// background: rgba(255, 255, 255, 0.7);
	top: -10px;
	left: -10px;
	margin: 0px;
	padding: 0px;
	z-index: 90;
	border-radius: 0px;
	border: none;
}

.button-dropdown {
	display: flex;
	align-items: center;
	justify-content: stretch;
	cursor: pointer;
	text-align: left;
	font-size: 14px;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-weight: 500;
	color: $text-color;
	background: none;
	padding: 10px;
	border: 1px solid $black;
	border-radius: $border-radius;
	white-space: nowrap;
	user-select: none;

	&:hover {
		border-color: $text-color;
	}
}

.button-dropdown-variant-primary .button-dropdown-active {
	border-color: $gray;
	color: $text-color;

	.button-dropdown-caret {
		stroke: $text-color;
	}
}

.button-dropdown-variant-subtle .button-dropdown {
	border: 0;
	border-bottom: 1px solid $light-gray;
	border-radius: 0;
}

.button-dropdown-label {
	flex-grow: 1;
}

.button-dropdown-caret {
	margin-left: 10px;
	stroke: $text-color;
}

.button-dropdown-container[aria-expanded="true"] {
	position: relative;
	z-index: 100;
}

.button-dropdown-items {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	justify-content: center;

	padding: 25px 15px 20px 20px;
	background-color: white;
	display: flex;
	flex-flow: column wrap;

	.button-dropdown-items-close {
		cursor: pointer;
		position: absolute;
		top: 0;
		right: 0;
		padding: 20px;
	}

	@media screen and (min-width: 1024px) {
		position: absolute;
		top: unset;
		right: unset;
		bottom: unset;
		left: unset;
		margin-top: 5px;
		border-radius: $border-radius;
		border: 1px solid $light-gray;
		box-shadow: 0 2px 6px $light-gray;
		min-width: 300px;
		max-width: 800px;

		.button-dropdown-items-close {
			display: none;
		}
	}
}
