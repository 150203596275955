@import "variables.scss";
@import "placeholders.scss";

.card-list-and-map-container {
	display: flex;
}

.card-list {
	margin: 0 20px;
	padding-bottom: 60px;
	flex-grow: 1;
}

.map-container {
	display: none;
	flex-grow: 1;
}

@media screen and (min-width: 1130px) {
	.map-container {
		display: block;
	}

	.card-list {
		flex-basis: 780px;
		flex-grow: 0;
	}
}

.card-container {
	@media screen {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	& > * {
		padding: 0 10px 20px 10px;
		flex-basis: 100%;
		@media screen and (min-width: 650px) {
			flex-basis: 50%;
		}
		@media screen and (min-width: 900px) {
			flex-basis: 33.333%;
		}
		@media screen and (min-width: 1130px) {
			flex-basis: 100%;
			padding: 0;
		}
		@media print {
			padding: 0;
		}
	}
}

.filters {
	padding: 14px 20px;
	flex-wrap: wrap;
	border-bottom: 1px solid $border-color;
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.filters > .filters-text {
	color: $text-color;
	font-weight: 700;
	margin-left: 10px;
	@media print {
		display: none;
	}
}

// Spacing between the buttons for different filters comes from here.
.filters > * {
	margin: 6px;
}

.results-summary {
	font-size: 22px;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	line-height: 41px;
	margin: 12px 0 12px 10px;
}

.results-summary-text {
	margin-right: 20px;
}

.btn--clear-filters {
	cursor: pointer;
	text-align: left;
	font-size: 14px;
	font-family: filson-pro, Verdana, "Open sans", sans-serif;
	font-weight: 700;
	color: $text-color;
	background: #fff url("/icons/icon-x.svg") 10px 50% no-repeat;
	padding: 10px 10px 10px 30px;
	border: 1px solid $light-gray;
	border-radius: $border-radius;
	white-space: nowrap;
	user-select: none;
	vertical-align: 2px;
	@media print {
		display: none;
	}
}
.btn--clear-filters:hover {
	text-decoration: none;
	border-color: $text-color;
}

.clear-filters-icon {
	margin-right: 9px;
	vertical-align: -1px;
}
